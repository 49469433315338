export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

export const users = {
  BVIRAL_SUPPORT: 'abdellatif_bviral',
  VIDEO_OWNER: 'test_abdellatif'
};

export const PAGE_SIZE = 15; // Number of messages to load per page

export const TWILIO_ACCESS_TOKEN =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTS2E1NTZhNDQ3N2Y0ZjkwZTk3MWE5NTMzNjNiNTJkMWJhLTE3MjkwNzgzNzIiLCJncmFudHMiOnsiY2hhdCI6eyJzZXJ2aWNlX3NpZCI6IklTODM0ZWI2ZmY1ODA5NGE4Mjk3OWNhNGZlMjEzZGU0YWEifSwiaWRlbnRpdHkiOiJ0ZXN0X2FiZGVsbGF0aWYifSwiaXNzIjoiU0thNTU2YTQ0NzdmNGY5MGU5NzFhOTUzMzYzYjUyZDFiYSIsImV4cCI6MTcyOTE2NDc3MiwibmJmIjoxNzI5MDc4MzcyLCJzdWIiOiJBQzA5OGFmNjIzOTRjYTJjYTQxMGM3OTJhZWI2YjhiZWViIn0.4hWlBJhXVejc2kuQO2kj9-VoVtN4vebzpDhKoDAVCWc';
export const CONVERSATION_SID = 'CH2057cb6c3eae4b19b47e6e0f5e3d51f3';
