import React, { Suspense, lazy } from 'react';
import LoadingScreen from './components/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const Chat = Loadable(lazy(() => import('./pages/Chat')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerDown = Loadable(lazy(() => import('./pages/ServerError')));
const Support = Loadable(lazy(() => import('./pages/Support')));

const routes = [
  {
    path: '/',
    element: <Chat />
  },
  {
    path: '/:uuid',
    element: <Chat />
  },
  {
    path: 'support',
    element: <Support />
  },
  {
    path: '500',
    element: <ServerDown />
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
