import 'nprogress/nprogress.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { HelmetProvider } from 'react-helmet-async';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import App from './App';

if (
  process.env.REACT_APP_ENVIRONMENT === 'staging' ||
  process.env.REACT_APP_ENVIRONMENT === 'production'
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration()
    ],
    ignoreErrors: [/NotAllowedError/],
    environment: process.env.REACT_APP_ENVIRONMENT
  });
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <StrictMode>
    <HelmetProvider>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <Sentry.ErrorBoundary fallback="An error has occurred">
              <App />
            </Sentry.ErrorBoundary>
          </BrowserRouter>
        </LocalizationProvider>
      </StyledEngineProvider>
    </HelmetProvider>
  </StrictMode>
);
